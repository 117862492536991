















import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { capitalizeString } from '~/utils/string'
import ContactForm from '~/components/car/dealers/site/page/content/contact/ContactForm.vue'
import OutletCard from '~/components/car/dealers/site/page/content/contact/OutletCard.vue'
import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import HeadService from '~/services/HeadService'
import DealerSiteLayoutService from '~/services/dealers/site/DealerSiteLayoutService'
import CCard from '~/components/shared/configurable/card/CCard.vue'

export default Vue.extend({
  components: {
    OutletCard,
    ContactForm,
    CCard
  },
  computed: {
    ...mapState(DEALER_SITE_NS, ['outlets', 'title']),
    ...mapGetters(DEALER_SITE_NS, [
      'activePage',
      'componentShouldRenderPage',
      'shouldAnimatePageTransition'
    ]),
    storeTitle() {
      return capitalizeString(this.$tc('store | stores', this.outlets.size))
    }
  },
  head() {
    const [headService, dealerSiteLayoutService] = this.$deps(
      HeadService,
      DealerSiteLayoutService
    )
    const head = headService.createHead()
    dealerSiteLayoutService.setPageTitle(head, this.$t('contact'))
    return head
  }
})
