











import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { Outlet } from '~/models/dealer/site/types'

import { DEALER_SITE_NS } from '~/store/modules/shared/dealers/site/state'
import CContactForm from '~/components/shared/configurable/CContactForm.vue'

export default Vue.extend({
  components: {
    CContactForm
  },
  data() {
    return {
      recipientId: null
    }
  },
  computed: {
    ...mapState(DEALER_SITE_NS, {
      websiteId: 'websiteId',
      activeOutletId: 'activeOutletId',
      outlets: 'outlets'
    }),
    ...mapGetters(DEALER_SITE_NS, ['getTemplateOption']),
    url(): string {
      return `/api/dsite/${this.websiteId}/contact/form/`
    },
    colConfig(): Record<string, string> {
      return {
        cols: '12',
        md: '12',
        lg: '12',
        xl: '12'
      }
    }
  },
  watch: {
    activeOutletId(value, prevValue) {
      if (value === prevValue) {
        return
      }
      this.setFormRecipientIdByOutletId(value)
    }
  },
  methods: {
    handleContactFormLoad() {
      this.setFormRecipientIdByOutletId(this.activeOutletId)
    },
    setFormRecipientIdByOutletId(id: string) {
      const form = this.$refs.contactForm.getForm()
      const { displayName: name }: Outlet = this.outlets.get(id)
      if (!name || !form?.recipients) {
        return
      }

      const recipient = form.recipients.find(r => r.name === name)
      if (!recipient || !recipient.id) {
        this.recipientId = form.recipients[0].id
        return
      }

      this.recipientId = recipient.id
    }
  }
})
